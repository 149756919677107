import React from 'react'
//表格样式
import { Row, Col, Image } from 'react-bootstrap'

const Qiyewang = () => {
  return (
    <div>
      <Row style={{ textAlign: 'center' }}>
        <Col md={12}><a href="https://video.qywdp.com/"><Image src="/images/1662602810.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com/"><Image src="/images/1662602955.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603027.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603081.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603178.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603233.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603323.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603371.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603581.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662603706.jpg" fluid /></a></Col>
        <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/1662608469.jpg" fluid /></a></Col>
      </Row>
    </div>
  )
}

export default Qiyewang
