//启动前端Desktop\webRTC> cd my-app//my-app> npm start
import React, { useEffect } from 'react';
//引进路由结构
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//项目说明
import ProjectExplained from './pages/IntroductionPage/ProjectExplained';
//引进这是项目介绍页面
import IntroductionPage from './pages/IntroductionPage/IntroductionPage';
//这是项目的加人房间页面
import JoinRoomPage from './pages/JoinRoomPage/JoinRoomPage';
//这是项目的房间页面--会议室
import RoomPage from './pages/RoomPage/RoomPage';
//客户端连接 socketio 服务器
import { connectWithSocketIOServer } from './utils/wss';
//企业网推广发送到邮箱
import SendEmail from './pages/IntroductionPage/SendEmail';
//企业网推广发送到邮箱
import Qiyewang from './pages/IntroductionPage/Qiyewang';

function App() {
  useEffect(() => {
    //客户端连接 socketio 服务器（在wss.js页面使用）
    connectWithSocketIOServer();
  }, []);
  return (
    <Router>
      {/* 页面自动排名 */}
      <Routes>
        {/* 企业网平台说明 */}
        <Route path='/qiyewang' element={<Qiyewang />} />
        {/* 项目说明 */}
        <Route path='/notYet' element={<ProjectExplained />} />
        {/* 企业网推广发送到邮箱 */}
        <Route path='/sendEmail' element={<SendEmail />} />
        {/* 进入房间路由 */}
        <Route path='/join-room' element={<JoinRoomPage />} />
        {/* 房间组件路由 */}
        <Route path='/room' element={<RoomPage />} />
        {/* 介绍页面路由 */}
        <Route path='/' element={<IntroductionPage />} />
      </Routes>
    </Router>
  );
}

export default App;
