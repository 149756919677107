import io from 'socket.io-client';
//引进服务器返回会议房间号
import store from '../store/store';
//引进服务器返回会议房间号//setParticipants派发参会人员//setSocketId找到了聊天对象派发
import { setRoomId, setParticipants, setSocketId } from '../store/actions';
//准备WedRTC对等连接
import * as webRTCHandler from './webRTCHandler';
//引进历史记录
import { appendNewMessageToChatHistory } from './directMessages';
//更改服务器端口需要同时更改server服务器端口
const SERVER = 'http://localhost:5000';

let socket = null;
//客户端连接 socketio 服务器
export const connectWithSocketIOServer = () => {
  socket = io(SERVER);
  socket.on('connect', () => {
    console.log('成功连接到socket.io 服务器');
    console.log(socket.id);
    //找到了聊天对象派发
    store.dispatch(setSocketId(socket.id));
  });
  //引进服务器返回会议房间号
  socket.on('room-id', (data) => {
    const { roomId } = data;
    store.dispatch(setRoomId(roomId));
  });
  socket.on('room-update', (data) => {
    //拿到了参会人员的数据
    const { connectedUsers } = data;
    //派发参会人员
    store.dispatch(setParticipants(connectedUsers));
  });
  //告知除自己以外的所有已连接用户准备webRTC对等连接
  socket.on('conn-prepare', (data) => {
    //connUserSocketId关羽向刘备发起
    const { connUserSocketId } = data;
    //准备webRTC连接(应答方-false)//connUserSocketId关羽向刘备发起
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, false);

    //通知对方我已经准备完毕可以进行webRTC连接//刘备向关羽发起conn-init触发事件//connUserSocketId向关羽响应//谁向我发起请求我就对谁响应
    socket.emit('conn-init', { connUserSocketId: connUserSocketId });
  });
  //交换信令数据//conn-signal向客服端发送一个事件//监听初始化对等连接//关羽向刘备发起
  socket.on('conn-signal', (data) => {
    //准备webRTC连接//实例关羽向刘备发起的对象
    webRTCHandler.handleSignalingData(data);
  });
  //监听初始化对等连接//刘备向关羽发起
  socket.on('conn-init', (data) => {
    //通过data进行结构//接收方的socketId是刘备socketId
    const { connUserSocketId } = data;
    //准备webRTC连接(发起方-true)//实例刘备的对象//connUserSocketId是刘备的//true实行对等连接
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, true);
  });
  //监听用户离开房间事件
  socket.on('user-disconected', (data) => {
    //断开对等连接方法
    webRTCHandler.removePeerConnection(data);
  });
  //信息发送监听
  socket.on('direct-message', (data) => {
    // console.log('成功获取发送的私信');
    // console.log(data);
    //将信息保存到历史记录里面//创建新的文件directMessages来处理这个方法
    appendNewMessageToChatHistory(data);
  });
};

//主持人创建会议房间
export const createNewRoom = (identity, onlyAudio) => {
  const data = {
    //身份
    identity,
    //音频切换
    onlyAudio,
  };
  //向服务器发送创建会议房间的数据（事件）
  socket.emit('create-new-room', data);
};

//加入会议房间
export const joinRoom = (roomId, identity, onlyAudio) => {
  //向服务器发送加入会议房间的数据（事件）
  const data = {
    roomId,
    //身份
    identity,
    //音频切换
    onlyAudio,
  };

  socket.emit('join-room', data);
};
//将信令数据发送到服务器
export const signalPeerData = (data) => {
  //向服务器发送一个事件
  socket.emit('conn-signal', data);
};
//引进所有导出数据方法
export const sendDirectMessage = (data) => {
  //调用socket发送数据
  socket.emit('direct-message', data);
};
