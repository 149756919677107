import React from 'react'
//表格样式
import { Row, Col, Image } from 'react-bootstrap'


const ProjectExplained = () => {
    return (
        <div>
            <Row style={{ textAlign: 'center' }}>
                <Col md={12}><a href="https://video.qywdp.com/"><Image src="/images/16593617.jpg" fluid /></a></Col> 
                <Col md={12}><a href="https://video.qywdp.com/"><Image src="/images/16593618.jpg" fluid /></a></Col>               
                <Col md={12}><a href="https://video.qywdp.com/"><Image src="/images/16593619.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923631.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923701.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923741.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923701.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923891.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923801.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923871.jpg" fluid /></a></Col>
                <Col md={12}><a href="https://www.qywdp.com"><Image src="/images/165923881.jpg" fluid /></a></Col>
            </Row>
        </div>
    )
}

export default ProjectExplained
