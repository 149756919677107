//这是视频展示区域
import React from 'react';
//引进视频所有按钮
import VideoButtons from './VideoButtons';

const VideoSection = () => {
  return (
    //视频展示区域外面容器
    <>
      <div className='video_section_container'>
        {/* 这是视频展示区域所有按钮 */}
        <VideoButtons />
      </div>
    </>
  );
};

export default VideoSection;
