import Actions from './actions';
const initState = {
  //存储用户姓名//进入房间的名称
  identity: '',
  //主持人默认值
  isRoomHost: false,
  //默认音频状态切换关闭
  connectOnlyWithAudio: false,
  //存储用户身份ID
  roomId: null,
  //加载动画
  showOverlay: true,
  //检查可以设置加载动画为关闭false
  // showOverlay: false,
  //已经连接的用户信息
  participants: [],
  //私信聊天//判断有没有激活功能
  activeConversation: null,
  //聊天记录
  directChatHistory: [],
  //socketId值//实时聊天
  socketId: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    //这是项目的加人房间页面
    case Actions.SET_IS_ROOM_HOST:
      return {
        //主持人原来的数据
        ...state,
        //更新主持人的数据
        isRoomHost: action.isRoomHost,
      };
    //音频状态切换长量
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return {
        ...state,
        //音频状态值
        connectOnlyWithAudio: action.onlyWithAudio,
      };
    //存储用户身份ID
    case Actions.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      };
    //存储用户姓名
    case Actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    //加载动画
    case Actions.SET_SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.showOverlay,
      };
    //已经连接的用户信息
    case Actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };
      //私信聊天//判断有没有激活功能
    case Actions.SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: action.activeConversation,
      };
      //聊天记录
    case Actions.SET_DIRECT_CHAT_HISTORY:
      return {
        ...state,
        directChatHistory: action.directChatHistory,
      };
      //socketId值//实时聊天
    case Actions.SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId,
      };

    default:
      return state;
  }
};

export default reducer;
