//这是项目介绍页面按钮组件set PORT=3001 && 
import React from 'react';

const ConnectingButton = ({
  //按钮状态//它的值作用是判定什么样的值
  createRoomButton = false,
  //按钮文本
  buttonText,
  //按钮的事件
  onClickHandler,
}) => {
  //判断按钮样式
  const buttonClass = createRoomButton
  //判断它的值是true
    ? 'create_room_button'
    //判断它的值是false
    : 'join_room_button';
  //派发的样式
  return (
    <button className={buttonClass} onClick={onClickHandler}>
      {/* 显示按钮文本 */}
      {buttonText}
    </button>
  );
};

export default ConnectingButton;
