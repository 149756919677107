//所有参与人员
import React from 'react';
//引进找到参与人员//判断有没有激活功能
import { setActiveConversation } from '../../../store/actions';
import { connect } from 'react-redux';
//单个参与人员
const SingelParticipant = (props) => {
  const {
    //用户姓名
    identity,
    //lastItem判断最后一行分格线 
    lastItem,
    //单个参与人员
    participant,
    //聊天的对象
    setActiveConversationAction,
    //找到的对象
    socketId,
  } = props;

  //激活私信聊天，获取对象信息
  const handleOpenActiveConversation = () => {
    //找到的对象//判断点击某个用户的时候，这个用户的socketId不等于您的socketId时就可以进行对话
    if (participant.socketId !== socketId) {
      //聊天的对象//participant您要聊天的用户
      setActiveConversationAction(participant);
    }
  };
  return (
    <>
      <p
        className='participants_paragraph'
        //点击事件开启私信对话
        onClick={handleOpenActiveConversation}
      >
        {/* identity显示用户姓名 */}
        {identity}
      </p>
      {/* 分格线设置//不是最后一行才去显示分格线//participants_separator_line分格线样式 */}
      {!lastItem && <span className='participants_separator_line'></span>}
    </>
  );
};
//显示房间内容名称
const Participants = ({
  //全部参与人员
  participants,
  //聊天的对象
  setActiveConversationAction,
  //找到的对象
  socketId,
}) => {
  return (
    //所有参与人员外面容器
    <div className='participants_container'>
      {/* dummyParticipants插入样板数据进行遍历 */}
      {participants.map((participant, index) => {
        return (
          //单个参与人员内容
          <SingelParticipant
          //用户姓名
            identity={participant.identity}
            //判断最后一行
            lastItem={participants.length === index + 1}
            //显示房间内容名称
            participant={participant}
            //数组添加key值
            key={participant.identity}
            //聊天的对象
            setActiveConversationAction={setActiveConversationAction}
            //找到的对象
            socketId={socketId}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
//找到参与人员//创建mapActionsToProps
const mapActionsToProps = (dispatch) => {
  return {
    //activeConversation聊天的对象
    setActiveConversationAction: (activeConversation) => {
      //进行派发//activeConversation接收聊天对象//setActiveConversation判断有没有激活功能
      dispatch(setActiveConversation(activeConversation));
    },
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Participants);
