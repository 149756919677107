//离开房间按钮
import React from 'react';
//离开房间变量
const LeaveRoomButton = () => {
  //设置路由重定向方式
  const handleRoomDisconnection = () => {
    // 动态获取接口
    const siteUrl = window.location.origin;
    // 设置当前定向到的URL
    window.location.href = siteUrl;
  };
  return (
    <div className='video_button_container'>
      <button className='video_button_end' onClick={handleRoomDisconnection}>
        离开房间
      </button>
    </div>
  );
};

export default LeaveRoomButton;
