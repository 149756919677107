//历史记录
import store from '../store/store';
//引进信息同步更新到store中
import { setDirectChatHistory } from '../store/actions';
export const appendNewMessageToChatHistory = (data) => {
  //isAuthor这个消息是由接收方还是发送方来作为历史记录保存//receiverSocketId, authorSocketId作用是用来查找历史记录
  const { isAuthor, receiverSocketId, authorSocketId } = data;

  //根据isAuhtor的值判断历史记录应该存储在那个用户（接收方/发送方）的历史记录里面
  if (isAuthor) {
    //作为消息的发送方去存储历史记录//receiverSocketId发送方
    appendMessageToChatHistory(receiverSocketId, data);
  } else {
    //作为消息的接收方去存储历史记录//authorSocketId接收方
    appendMessageToChatHistory(authorSocketId, data);
  }
};
//userSocketId所有用户
const appendMessageToChatHistory = (userSocketId, data) => {
  //找到存储在store中的历史记录 --> directChatHistory:[]
  //chatHistory创建变量找到历史记录
  const chatHistory = [...store.getState().directChatHistory];
  //找到其中单个用户的历史记录 -->userSocketId
  const userChatHistory = chatHistory.find(
    //socketId等于userSocketId所有用户
    (history) => history.socketId === userSocketId
  );

  //验证userChatHistory是否存在
  if (userChatHistory) {
    //如果存在历史记录就将新获取的消息添加进入
    //创建获取的新会话
    const newDirectMessage = {
      //是谁创建的消息
      isAuthor: data.isAuthor,
      //保存的真实数据，信息内容
      messageContent: data.messageContent,
      //这条消息是谁发送的
      identity: data.identity,
    };
    //创建新的用户历史记录
    const newUserChatHistory = {
      //单个用户的历史记录
      ...userChatHistory,
      //chatHistory单个的历史记录//chatHistory是每一个用户存储在他的对象当中的数组，这个数组会保存所有历史记录
      chatHistory: [...userChatHistory.chatHistory, newDirectMessage],
    };

    //替换掉用户上一次的历史记录（过滤处理）
    //newChatHistory完整的历史记录（所有的历史记录）//将信息添加进去重新保存一个新的数组
    const newChatHistory = [
      //chatHistory单个的历史记录//...chatHistory原来的数据//socketId等于userSocketId所有用户
      ...chatHistory.filter((history) => history.socketId !== userSocketId),
      //添加新的历史记录
      newUserChatHistory,
    ];
    //信息同步到store中//newChatHistory派发完整的历史记录
    store.dispatch(setDirectChatHistory(newChatHistory));
  } else {
    //如果历史记录不存在就重新创建该用户的历史记录
    const newUserChatHistory = {
      //socketId就是当前我们所接收到的用户他的ID
      socketId: userSocketId,
      //chatHistory单个的历史记录//chatHistory是每一个用户存储在他的对象当中的数组，这个数组会保存所有历史记录
      chatHistory: [
        {
          //是谁创建的消息
          isAuthor: data.isAuthor,
          //保存的真实数据，信息内容
          messageContent: data.messageContent,
          //这条消息是谁发送的
          identity: data.identity,
        },
      ],
    };
    //newChatHistory完整的历史记录（所有的历史记录）//将...chatHistory, newUserChatHistory添加进去重新保存一个新的数组
    const newChatHistory = [...chatHistory, newUserChatHistory];
    //信息同步更新到store中//newChatHistory派发完整的历史记录
    store.dispatch(setDirectChatHistory(newChatHistory));
  }
};
