//这是视频展示区域所有按钮
import React from 'react';
//引进摄像头按钮
import CameraButton from './CameraButton';
//引进离开房间按钮
import LeaveRoomButton from './LeaveRoomButton';
//引进麦克风按钮
import MicButton from './MicButton';
//引进共享按钮
import SwitchToScreenSharingButton from './SwitchToScreenSharingButton';
//引进connect 方法//隐藏按钮设置
import { connect } from 'react-redux';
//{ connectOnlyWithAudio }值为true的时候才去显示隐藏按钮设置
const VideoButtons = ({ connectOnlyWithAudio }) => {
  return (
    <>
      {/* 视频展示区域所有按钮外面容器 */}
      <div className='video_buttons_container'>
        <a className='contact' href="https://qywdp.com"><strong>企业网</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/enterprises"><strong>官网</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/fastFoods"><strong>饭店快餐</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/exhibitionListXs"><strong>展览会</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/salesListXs"><strong>进销存</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/myManagements"><strong>销售</strong></a>
        {/* 麦克风按钮 */}
        <strong><MicButton /></strong>
        {/* 摄像头按钮//{ connectOnlyWithAudio }值为true的时候才去显示隐藏按钮设置 */}
        <strong>{!connectOnlyWithAudio && <CameraButton />}</strong>
        {/* 共享按钮//{ connectOnlyWithAudio }值为true的时候才去显示隐藏按钮设置 */}
        <strong>{!connectOnlyWithAudio && <SwitchToScreenSharingButton />}</strong>
        {/* 离开房间按钮 */}
        <strong><LeaveRoomButton /></strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/recruitmentListXs"><strong>招聘</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/marriageProposals"><strong>征婚</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/houses"><strong>房屋</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/applicationBownloads"><strong>下载</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://drawLots.qywdp.com"><strong>抽签</strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='contact' href="https://qywdp.com/personalCenter"><strong>管理</strong></a>
      </div>
    </>
  );
};
//隐藏按钮设置
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(VideoButtons);
