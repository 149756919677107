//这是项目介绍页面//useEffect去派发主持人缓冲处理方法
import React, { useEffect } from 'react';
//引进面板图片样式
import logo from '../../resources/images/logo.png';
//引进按钮
import ConnectingButtons from './ConnectingButtons';
//引进样式
import './IntroductionPage.css';
//引进主持人缓冲处理方法
import { connect } from 'react-redux';
//去派发主持人缓冲处理方法
import { setIsRootHost } from '../../store/actions';
//setIsRoomHostAction去结构主持人缓冲处理方法
const IntroductionPage = ({ setIsRoomHostAction }) => {
  //去派发主持人缓冲处理方法
  useEffect(() => {
    //默认host状态为false//主持人每次进入放行
    setIsRoomHostAction(false);
  }, [setIsRoomHostAction]);

  return (
    //外部容器
    <div className='introduction_page_container'>
      {/* 中心面板 */}
      <div className='introduction_page_panel'>
        {/* 面板图片 */}
        <img src={logo} className='introduction_page_image' alt='' />
        {/* 按钮显示 */}
        <ConnectingButtons />        
      </div>
    </div>
  );
};
//引进主持人缓冲处理方法
const mapActionsToProps = (dispatch) => {
  return {
    //去派发主持人缓冲处理方法//通过isRoomHost行为状态进行修改
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRootHost(isRoomHost)),
  };
};
//引进主持人缓冲处理方法绑定connect
export default connect(null, mapActionsToProps)(IntroductionPage);
