//普通用户验证方法//负责路由路线//此页面安装请求cd Desktop/webRTC/npm install --save axios
import axios from 'axios';
//http://localhost:5000/api部署后修改为自己的地址https://www.qywdp.com/api
//创建变量//后台serverApi
const serverApi = 'http://localhost:5000/api';

export const getRoomExists = async (roomId) => {
  //请求serverApi的路由路线//room-exists验证房间是否存在
  const response = await axios.get(`${serverApi}/room-exists/${roomId}`);
  //通过data来获取数据 
  return response.data;
};
