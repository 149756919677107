//显示聊天记录//useRef作用去设置捆动条//useEffect作用去设置捆动条
import React, { useRef, useEffect } from 'react';
//构建单个的SingleMessage组件//isAuthor//判断是由我发送的还是接收的//message内容
const SingleMessage = ({ isAuthor, messageContent }) => {
  const messageStyling = isAuthor
  //author_direct_message发送的内容
    ? 'author_direct_message'
    //receiver_direct_message接收的内容
    : 'receiver_direct_message';
  //创建文本的内容
  const containerStyling = isAuthor
  //发送的内容
    ? 'direct_message_container_author'
    //接收的内容
    : 'direct_message_messageStylingcontainer_receiver';
  //返回单个的SingleMessage组件结果
  return (
    <div className={containerStyling}>
      <p className={messageStyling}>{messageContent}</p>
    </div>
  );
};
//{ messages }结构{ messages }
const MessagesContainer = ({ messages }) => {
  //useRef作用去设置捆动条
  const scrollRef = useRef();
  //useEffect作用去设置捆动条
  useEffect(() => {
    //让元素平滑滚动到窗口的可视区域
    if (scrollRef) {
      //scrollRef捆动条存在的时候去调用current.scrollIntoView({ behavior: 'smooth' });
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    //messages捆动条依赖行
  }, [messages]);
  return (
    //显示聊天记录外面容器
    <div className='direct_messages_container'>
      {/* 包括所有的messages//进行遍历 */}
      {messages.map((message) => {
        return (
          //单独的message
          <SingleMessage
          //message内容
            messageContent={message.messageContent}
            //发布者的身份
            identity={message.identity}
            //判断是由我发送的还是接收的
            isAuthor={message.isAuthor}
            //设置key值//- ${message.identity}发布者姓名
            key={`${message.messageContent} - ${message.identity}`}
          />
        );
      })}
      {/* <div ref={scrollRef}></div>作用去设置捆动条 */}
      <div ref={scrollRef}></div>
    </div>
  );
};

export default MessagesContainer;
