//共享按钮
import React, { useState } from 'react';
//引进共享按钮图片关闭状态
import SwitchImg from '../../../resources/images/switchToScreenSharing.svg';
//引进预览共享本地资源
import LocalScreenSharingPreview from './LocalScreenSharingPreview';
//引进共享屏幕切换
import * as webRTCHandler from '../../../utils/webRTCHandler';
//设置一个变量定义为constrains作为参数
const constrains = {
  audio: false,
  video: true,
};
//去选择您要共享的屏幕内容
const SwitchToScreenSharingButton = () => {
  //共享按钮当前为关闭状态
  const [isScreenSharingActive, setIsScreenSharingActive] = useState(false);
  //共享初始化
  const [screenSharingStream, setScreenSharingStream] = useState(null);
  // 共享按钮切换方式
  const handleScreenShareToggle = async () => {
    //异步判断isScreenSharingActive有没有激活
    if (!isScreenSharingActive) {
      //创建变量来存储媒体资源，值为空
      let stream = null;
      try {
        //stream的存储//获取本地要共享的媒体资源//通过navigator调用mediaDevices媒体设备//getDisplayMedia方法能显示所有信息//constrains接收上面定义的参数
        stream = await navigator.mediaDevices.getDisplayMedia(constrains);
      } catch (error) {
        console.log('获取共享屏幕的媒体流失败');
      }
      //stream存在的情况
      if (stream) {
        //作为组件级状态
        setScreenSharingStream(stream);
        //共享屏幕切换调用//isScreenSharingActive是否要进行屏幕共享
        webRTCHandler.toggleScreenShare(isScreenSharingActive, stream);
        //激活的状态
        setIsScreenSharingActive(true);
      }
    } else {
      //共享屏幕切换调用//isScreenSharingActive停止屏幕共享
      webRTCHandler.toggleScreenShare(isScreenSharingActive);
      //视频轨道切换
      setIsScreenSharingActive(false);
      //停止共享屏幕//getTracks()返回一个数组//通过forEach去遍历出来
      screenSharingStream.getTracks().forEach((track) => track.stop());
      //进行清空
      setScreenSharingStream(null);
    }

    // setIsScreenSharingActive(!isScreenSharingActive);
  };
  return (
    <>
      <div className='video_button_container'>
        <img
        //共享按钮当前状态为关闭//点击为开通
          src={SwitchImg}
          //共享按钮开关点击事件
          onClick={handleScreenShareToggle}
          //共享按钮图片样式
          className='video_button_image' alt=''
        />
      </div>
      {/* 预览共享本地资源 */}
      {isScreenSharingActive && (
        <LocalScreenSharingPreview stream={screenSharingStream} />
      )}
    </>
  );
};

export default SwitchToScreenSharingButton;
