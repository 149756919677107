//这是项目的加人房间页面
import React, { useEffect } from 'react';
//useLocation这个属性能够拿到查询字符串？后面的内容，判断访问者的身份
import { useLocation } from 'react-router-dom';
//引进connect 方法
import { connect } from 'react-redux';
//去派发action行为
import { setIsRootHost } from '../../store/actions';
//引进加入房间
import JoinRoomTitle from './JoinRoomTitle';
//引进样式
import './JoinRoomPage.css';
//引进房间主体内容
import JoinRoomContent from './JoinRoomContent';

const JoinRoomPage = (props) => {
  //去派发action变量//进入房间变量//根据isRoomHost状态来决定
  const { setIsRoomHostAction, isRoomHost } = props;
  //useLocaltion返回URL的location对象，search属性返回的是问号之后的查询字符串
  const search = useLocation().search;

  useEffect(() => {
    //判断是否主持人//host是key的意思
    const isRoomHost = new URLSearchParams(search).get('host');

    if (isRoomHost) {
      //将主持人的状态保存到redux的store里面
      setIsRoomHostAction(isRoomHost);
    }
  }, [search, setIsRoomHostAction]);
  return (
    //加人房间页面外面的容器
    <div className='join_room_page_container'>
      {/* 加人房间面板 */}
      <div className='join_room_page_panel'>
        {/* 面板内容/加入房间//根据isRoomHost状态来决定 */}
        <JoinRoomTitle isRoomHost={isRoomHost} />
        {/* 面板主体内容（主持人） */}
        <JoinRoomContent />
      </div>
    </div>
  );
};
//connect方法一
//将 store 作为 props 绑定到组件上
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
//connect方法二
//将 action 作为 props 绑定到组件上，
const mapActionsToProps = (dispatch) => {
  return {
    //去派发action行为
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRootHost(isRoomHost)),
  };
};

//connect 方法，链接react组件与redux store，允许我们将 store 中的数据作为 props 绑定到组件上。
export default connect(mapStateToProps, mapActionsToProps)(JoinRoomPage);
