//这是项目的房间页面--会议室//结构useEffect调用获取用户本地媒体流许可函数getLocalPreviewAndInitRoomConnection
import React, { useEffect } from 'react';
//表格样式
import { Row, Col } from 'react-bootstrap'
//引进参与人员加入
import ParticipantsSection from './ParticipantsSection/ParticipantsSection';
//引进会议房间ID号
import RoomLabel from './RoomLabel';
//引进connect方法
import { connect } from 'react-redux';
//获取本地媒体流
import * as webRTCHandler from '../../utils/webRTCHandler';
//引进样式
import './Roompage.css';
//这是视频展示区域
import VideoSection from './VideoSection/VideoSection';
//引进动画组件
import Overlay from './Overlay';
const RoomPage = ({
  //存储用户身份ID
  roomId,
  //主持人状态
  isRoomHost,
  //存储用户姓名
  identity,
  //加载动画
  showOverlay,
  //音频切换状态
  connectOnlyWithAudio,
}) => {
  useEffect(() => {
    //路由守卫//判断isRoomHost存不存在或者roomId存不存在/不存在重定向页面
    if (!isRoomHost && !roomId) {
      // 动态获取接口
      const siteUrl = window.location.origin;
      // 设置当前定向到的URL
      window.location.href = siteUrl;
    }
    //通过webRTCHandler调用getLocalPreviewAndInitRoomConnection函数
    webRTCHandler.getLocalPreviewAndInitRoomConnection(//内面传递所有参数
      //主持人状态
      isRoomHost,
      //存储用户姓名
      identity,
      //存储用户身份ID
      roomId,
      //音频切换状态
      connectOnlyWithAudio
    );
    //添加依赖项//roomId不能做依赖项
  }, [connectOnlyWithAudio, identity, isRoomHost]);

  return (
    <>
      <Row>
        <Col md={8}>{/* 会议室外面容器 */}
          <div className='room_container'>
            {/* 这是视频展示区域 */}
            <VideoSection />
            {/* 会议房间ID号 */}
            <RoomLabel roomId={roomId} />
            {/* 加载动画组件 */}
            {showOverlay && <Overlay />}
          </div>
        </Col>
        <Col md={4}>
          {/* 会议室外面容器 */}
          <div className='room_container'>
            {/* 参与人员加入 */}
            <ParticipantsSection />
            {/* 加载动画组件 */}
            {showOverlay && <Overlay />}
          </div>
        </Col>
      </Row>
    </>
  );
};
//引进connect方法
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
//绑定connect方法
export default connect(mapStateToProps)(RoomPage);
