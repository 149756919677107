//预览共享本地资源
import React, { useRef, useEffect } from 'react';

const LocalScreenSharingPreview = ({ stream }) => {
  //创建变量//useRef值
  const localPreviewRef = useRef();
  //通过useEffect实现状态切换
  useEffect(() => {
    //根据视频流设定
    const video = localPreviewRef.current;
    //通过video调用方法
    video.srcObject = stream;
    //监听数据是否加载完成
    video.onloadedmetadata = () => {
      //数据加载完成才去播放
      video.play();
    };
    //根据stream的变化来变动
  }, [stream]);
  return (
    //预览共享本地资源外面容器
    <div className='local_screen_share_preview'>
      {/* 设置视频的容器  muted静音  autoPlay自动播放  绑定上面变量localPreviewRef */}
      <video muted autoPlay ref={localPreviewRef} />
    </div>
  );
};

export default LocalScreenSharingPreview;
