//会议房间ID号
import React from 'react';

const RoomLabel = ({ roomId }) => {
  return (
    //外面容器
    <div className='room_label'>
      <p className='room_label_paragraph'>{roomId}</p>
    </div>
  );
};

export default RoomLabel;
