//没有激活的时候所展示的信息（没有激活或者没有选择）
import React from 'react';

const ConversationNotChosen = () => {
  return (
    //没有激活的时候所展示的信息外面容器
    <div className='conversation_not_chosen_overlay'>
      {/* 文本样式 */}
      <p className='conversation_not_chosen_overlay_text'>请选择聊天对象</p>
    </div>
  );
};

export default ConversationNotChosen;
