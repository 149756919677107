//视频摄像头按钮
import React, { useState } from 'react';
//引进摄像头图片开通状态
import CameraButtonImg from '../../../resources/images/camera.svg';
//引进视频摄像头图片关闭状态
import CameraButtonImgOff from '../../../resources/images/cameraOff.svg';
//引进视频摄像头函数
import * as webRTCHandler from '../../../utils/webRTCHandler';

const CameraButton = () => {
  //视频摄像头当前状态为开通
  const [isLcoalVideoDisabled, setIsLcoalVideoDisabled] = useState(false);
  //摄像头开关点击事件
  const handleCameraButtonPressed = () => {
    //通过webRTCHandler调用toggleCamera接收isLcoalVideoDisabled的状态
    webRTCHandler.toggleCamera(isLcoalVideoDisabled);
    //摄像头开关切换
    setIsLcoalVideoDisabled(!isLcoalVideoDisabled);
  };
  return (
    <div className='video_button_container'>
      <img
      //摄像头当前状态为开通//点击为关闭
        src={isLcoalVideoDisabled ? CameraButtonImgOff : CameraButtonImg}
        //摄像头开关点击事件
        onClick={handleCameraButtonPressed}
        //摄像头图片样式
        className='video_button_image' alt=''
      />
    </div>
  );
};

export default CameraButton;
