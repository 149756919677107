//输入框组件
import React, { useState } from 'react';
//引进图片
import sendMessageButton from '../../../../resources/images/sendMessageButton.svg';
//引进store的信息
import { connect } from 'react-redux';
//引进所有导出方法
import * as wss from '../../../../utils/wss';
//activeConversation聊天的所有内容//identity身份
const NewMessage = ({ activeConversation, identity }) => {
  //单个message初始化默认值
  const [message, setMessage] = useState('');
  //第一个事件//创建变量来保存//通过event来监听
  const handleTextChange = (event) => {
    //得到的值作为组件级状态保存
    setMessage(event.target.value);
  };
  //第二个事件回车键//第一个事件
  const handleKeyDown = (event) => {
    //按回车键的时候要先做判断//通过event的key值来进行判断//Enter回车键
    if (event.key === 'Enter') {
      //取消默认事件
      event.preventDefault();
      //通过sendMessage()传递内容 
      sendMessage();
    }
  };
  //创建Message//发送数据
  const sendMessage = () => {
    //发送消息//结建要发送信息的hadler事件
    wss.sendDirectMessage({
      //这条信息要发送给谁的//receiverSocketId要接收的用户ID值//activeConversation.socketId激活的用户ID
      receiverSocketId: activeConversation.socketId,
      //是谁创建的
      identity: identity,
      //发送的信息内容
      messageContent: message,
    });
    //清空用户在输入框填写的信息
    setMessage('');
  };

  return (
    //new_message_container单个message外面容器//new_message_direct_border独有的样式
    <div className='new_message_container new_message_direct_border'>
      <input
      //输入框样式
        className='new_message_input'
        //存储的组件级状态
        value={message}
        //点击事件
        onChange={handleTextChange}
        //输入的文本
        placeholder='请输入消息'
        //文本类型
        type='text'
        //回车键点击事件
        onKeyDown={handleKeyDown}
      />
      {/* 发送图片 */}
      <img
      //按钮样式
        className='new_message_button'
        //图片引进
        src={sendMessageButton}
        //点击事件
        onClick={sendMessage}
        alt=''
      />
    </div>
  );
};
//引进store的信息
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
//connect引进store的信息方法
export default connect(mapStateToProps)(NewMessage);
