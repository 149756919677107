//私信聊天组件
import React, { useState, useEffect } from 'react';
//引进没有激活的时候所展示的信息
import ConversationNotChosen from './ConversationNotChosen';
//引进聊天标题
import DirectChatHeader from './DirectChatHeader';
//引进显示聊天记录
import MessagesContainer from './MessagesContainer';
//引进输入框组件
import NewMessage from './NewMessage';
//获取store的信息//找到的对象
import { connect } from 'react-redux';

//获取用户的历史记录//directChatHistory聊天记录//socketId = null如果传递过来的参数ID不存在默认值设置
const getDirectChatHistory = (directChatHistory, socketId = null) => {
  //是否存在directChatHistory或者!socketId不存在//!directChatHistory没有聊天记录
  if (!socketId || !directChatHistory) {
    //不存在返回空数组
    return [];
  }
  //聊天记录存在变量//history对象//directChatHistory聊天记录//find查找的意思
  const history = directChatHistory.find(
    (history) => history.socketId === socketId
  );
  //history对象
  return history ? history.chatHistory : [];
};
//私信聊天组件变量//activeConversation判断是否要激活私聊//directChatHistory聊天记录
const DirectChat = ({ activeConversation, directChatHistory }) => {
  //设置messages默认值为空数组来保存//所有聊天内容
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    //它会根据每一次的变化传递动态值
    setMessages(
      //获取用户的历史记录
      getDirectChatHistory(
        //directChatHistory聊天记录
        directChatHistory,
        //activeConversation判断是否要激活私聊//获取单个用户的方法
        activeConversation ? activeConversation.socketId : null
      )
    );
    //activeConversation判断是否要激活私聊//directChatHistory聊天记录
  }, [activeConversation, directChatHistory]);
  return (
    //私信聊天组件外面容器 
    <div className='direct_chat_container'>
      {/* 插入整个聊天框的内容 */}
      {/* 聊天标题 */}
      <DirectChatHeader activeConversation={activeConversation} />
      {/* 显示聊天记录//所有聊天内容 */}
      <MessagesContainer messages={messages} />
      {/* 输入框组件 */}
      <NewMessage />
      {/* 没有激活的时候所展示的信息//它的作用去显示一个简单的文本告知还没有激活私信对象 */}
      {!activeConversation && <ConversationNotChosen />}
    </div>
  );
};
//获取store的信息//找到的对象
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DirectChat);
