const Actions = {
  //主持人长量
  SET_IS_ROOM_HOST: 'SET_IS_ROOM_HOST', 
  //音频状态切换长量
  SET_CONNECT_ONLY_WITH_AUDIO: 'SET_CONNECT_ONLY_WITH_AUDIO',
  //存储用户身份ID
  SET_ROOM_ID: 'SET_ROOM_ID',
  //存储用户身份姓名
  SET_IDENTITY: 'SET_IDENTITY',
  //加载动画
  SET_SHOW_OVERLAY: 'SET_SHOW_OVERLAY',
  //显示参会人员
  SET_PARTICIPANTS: 'SET_PARTICIPANTS',
  //私信聊天//判断有没有激活功能
  SET_ACTIVE_CONVERSATION: 'SET_ACTIVE_CONVERSATION',
  //聊天记录
  SET_DIRECT_CHAT_HISTORY: 'SET_DIRECT_CHAT_HISTORY',
  //socketId值//实时聊天
  SET_SOCKET_ID: 'SET_SOCKET_ID',
};
//主持人的判断
export const setIsRootHost = (isRoomHost) => {
  return {
    //主持人长量
    type: Actions.SET_IS_ROOM_HOST,
    //主持人的判断
    isRoomHost,
  };
};
//链接音频状态切换
export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    //音频状态切换长量
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};
//存储用户姓名
export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};
//存储用户身份ID
export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};
//加载动画
export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};
//显示参会人员
export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};
//私信聊天//判断有没有激活功能
export const setActiveConversation = (activeConversation) => {
  return {
    type: Actions.SET_ACTIVE_CONVERSATION,
    activeConversation,
  };
};
//聊天记录
export const setDirectChatHistory = (directChatHistory) => {
  return {
    type: Actions.SET_DIRECT_CHAT_HISTORY,
    directChatHistory,
  };
};
//socketId值//实时聊天
export const setSocketId = (socketId) => {
  return {
    type: Actions.SET_SOCKET_ID,
    socketId,
  };
};
export default Actions;
