import React from 'react'

const SendEmail = () => {
  return (
    <div className='titleFont'>
      恭喜您获得企业网平台：免费发布产品及推广，企业网是一家综合性互联网服务平台，企业网平台有66个分类系统（实行免费发布产品及产品推广，注册账户后立即可以发布及推广，请您遵守国家法律法规及平台规定进行发布及推广），我们设置了管理登录及客服登录和主账号登录、可以叫工人去管理企业，减少企业老板的工作，我们会推出更多的系统。您可以登录<strong className='red'>https://qywdp.com</strong>企业网平台进行发布及推广,河源市金仙信息科技股份有限公司欢迎您！联系电话：0762-6481206
    </div>
  )
}

export default SendEmail
