//加入房间按钮组件
import React from 'react';
//返回到上一级页面设置
import { useNavigate } from 'react-router-dom';
//Button按钮切换组件
//buttonText文本显示//cancelButton取消的按钮//onClickHandler触发事件
const Button = ({ buttonText, cancelButton = false, onClickHandler }) => {
  //按钮切换内容//buttonClass按钮的样式
  const buttonClass = cancelButton
    //cancelButton = false
    ? 'join_room_cancel_button'
    //cancelButton = true为加入
    : 'join_room_success_button';
  //上面按钮的结果返回
  return (
    //按钮样式buttonClass//onClick点击事件
    <button className={buttonClass}
      //点击事件
      onClick={onClickHandler}>
      {/* buttonText文本显示 */}
      {buttonText}
    </button>
  );
};
//handleJoinRoom用于普通用户加入房间验证//isRoomHost判断是否主持人
const JoinRoomButtons = ({ handleJoinRoom, isRoomHost }) => {
  //isRoomHost的状态为false显示主持人//isRoomHost的状态为true显示加入
  const successButtonText = isRoomHost ? '主持' : '加入';
  //返回到上一级页面变量
  const navigate = useNavigate();
  //返回到介绍页面//pushToIntroductionPage返回到上一级页面
  const pushToIntroductionPage = () => {
    //返回到上一级页面重定向
    navigate('/');
  };
  return (
    //加入房间按钮组件外面容器
    <div className='join_room_buttons_container'>
      {/* 按钮的使用//successButtonText主持人状态 */}
      <Button buttonText={successButtonText}
        //点击事件//handleJoinRoom后续要定向页面
        onClickHandler={handleJoinRoom} />
      <Button
        buttonText='取消'
        //pushToIntroductionPage返回到上一级页面
        onClickHandler={pushToIntroductionPage}
        //按钮的默认值//cancelButton默认的时候是false
        cancelButton
      />
    </div>
  );
};

export default JoinRoomButtons;
