//获取用户本地媒体流许可
//引进加载动画仓库
import store from '../store/store';
//setShowOverlay引进加载动画状态//setMessages聊天室消息内容存储
import { setShowOverlay } from '../store/actions';
//引进对等连接
import Peer from 'simple-peer';
//引进用户进入房间
import * as wss from './wss';
//指定请求的媒体类型和相对应的参数。
const defaultConstraints = {
  audio: true,
  video: { width: '480', height: '360' },
};

//仅开启音频链接//媒体流轨道
const onlyAudioConstraints = {
  //媒体流音频轨道参数
  audio: true,
  //媒体流视频轨道参数//音频默认false
  video: false,
};
//stream流变量本地存储
let localStream;

//获取本地预览及初始化房间连接//先采集到音视频，然后初始化连接
export const getLocalPreviewAndInitRoomConnection = async (
  //主持人创建房间//不是主持人加入房间
  isRoomHost,
  //用户姓名
  identity,
  //用户id
  roomId = null,
  //音频切换
  onlyAudio
) => {
  //判断是开启音频还是音视频
  const constrains = onlyAudio ? onlyAudioConstraints : defaultConstraints;

  //采集本地音视频流（获取媒体输入的访问权限）
  navigator.mediaDevices
    .getUserMedia(constrains)
    .then((stream) => {
        console.log('成功获取本地媒体流');
        //本地媒体流存储
      localStream = stream;
      //预览本地视频//接收上面变量的localStream
      showLocalVideoPreview(localStream);

      //派发action隐藏加载动画
      store.dispatch(setShowOverlay(false));

      //现在对服务器发起请求加入房间
      //初始化房间连接
      isRoomHost
      //isRoomHost为true就创建房间//isRoomHost为false就加入房间//onlyAudio音频切换
        ? wss.createNewRoom(identity, onlyAudio)
        //onlyAudio音频切换
        : wss.joinRoom(roomId, identity, onlyAudio);
    })
    .catch((error) => {
      console.log('无法获取本地媒体流！');
      console.log(error);
    });
};
//实例化对等连接对象变量
let peers = {};
let streams = [];
//配置STUN服务器
const getConfiguration = () => {
  return {
    iceServers: [
      {
        //配置STUN服务器
        urls: 'stun:stun1.l.google.com:19302',
      },
    ],
  };
};

//准备webRTC连接
export const prepareNewPeerConnection = (connUserSocketId, isInitiator) => {
  //配置STUN服务器
  const configuration = getConfiguration();
  //实例化对等连接对象
  peers[connUserSocketId] = new Peer({
    //isInitiator值为true才能对等连接
    initiator: isInitiator,
    //配置STUN服务器
    config: configuration,
    //媒体流//预览本地视频
    stream: localStream,
  });

  //信令数据传递
  peers[connUserSocketId].on('signal', (data) => {
    //==>peer1.on('signal', data)
    //data - webrtc offer, answer, or ice candidate
    //信令数据变量
    const signalData = {
      //信令数据
      signal: data,
      //刘备的SocketId
      connUserSocketId: connUserSocketId,
    };
    wss.signalPeerData(signalData);
  });

  //获取媒体流stream
  peers[connUserSocketId].on('stream', (stream) => {
    console.log('成功获取远程Stream');
    //显示接收的stream媒体流
    addStream(stream, connUserSocketId);
    streams = [...streams, stream];
  });
};
//将信令数据添加到接收webRTC对等连接准备的一方的对等对像中
export const handleSignalingData = (data) => {//peer2.signal(data);
  //将信令数据添加到对等连接中
  peers[data.connUserSocketId].signal(data.signal); //==> peer2.signal(data)
};
//监听用户离开房间事件的函数
export const removePeerConnection = (data) => {
  //结构用户离开房间的ID值
  const { socketId } = data;
  //获取要离开的用户包括整个视频元素
  const videoContainer = document.getElementById(socketId);
  //自身的唯一ID
  const videoElement = document.getElementById(`${socketId}-video`);
  //判断2个内容是否存在，2个都存在的情况下可以删除
  if (videoContainer && videoElement) {
    //创建单独的变量来保存//getTracks()会返回一个数组（媒体流）
    const tracks = videoElement.srcObject.getTracks();
    //循环遍历
    tracks.forEach((track) => track.stop());
    //重新定义离开房间用户的样式//现在就没有音视频信息
    videoElement.srcObject = null;
    //现在进行删除videoElement
    videoContainer.removeChild(videoElement);
    //先找到parentNode父节点然后删除videoContainer自身
    videoContainer.parentNode.removeChild(videoContainer);
    //需要进一步删除对等对像整个容器
    if (peers[socketId]) {
      //断开整个连接
      peers[socketId].destroy();
    }
    //现在完全删除用户离开房间所有信息
    delete peers[socketId];
  }
};

/////////////////////////////// Video UI ///////////////////////////////

//显示本地视频
const showLocalVideoPreview = (stream) => {
  //设置显示视频容器
  const videosContainer = document.getElementById('videos_portal');
  //添加视频外面的容器样式//这里可以设置一个判断如果视频设备小于平板尺寸显示全屏/解决手机显示为全屏
  videosContainer.classList.add('videos_portal_styles');
  //设置单个div的容器
  const videoContainer = document.createElement('div');
  //设置单个div的容器样式
  videoContainer.classList.add('video_track_container');
  //显示单个容器
  const videoElement = document.createElement('video');
  //设置video的属性
  videoElement.autoplay = true;
  //设置音视频是否静音//默认我静音//上线需要修改为false
  videoElement.muted = false;
  //设置音视频资源样式
  videoElement.srcObject = stream;

  //onloadedmetadata在指定视频/音频（audio/video）的元数据加载后触发。
  videoElement.onloadedmetadata = () => {
    //音视频加载之后才去播放
    videoElement.play();
  };
  //将video插入到对应容器当中
  videoContainer.appendChild(videoElement);

  //仅开启音频的样式//根据状态判断
  if (store.getState().connectOnlyWithAudio) {
    //最终显示文本
    videoContainer.appendChild(onlyAudioLabel());
  }
  //将单个的容器插入到所有的容器中
  videosContainer.appendChild(videoContainer);
};

//添加接收的stream媒体流并进行显示
const addStream = (stream, connUserSocketId) => {
  //使用js创建容器展示视频
  const videosContainer = document.getElementById('videos_portal');
  //设置单个div的容器
  const videoContainer = document.createElement('div');
  //videoContainer.id用户离开房间的时候删除用户所有信息
  videoContainer.id = connUserSocketId;
  //设置单个div的容器样式
  videoContainer.classList.add('video_track_container');
  //显示单个容器
  const videoElement = document.createElement('video');
  //设置video的属性
  videoElement.autoplay = true;
  //设置音视频是否静音//默认我静音//上线需要修改为false
  videoElement.muted = true;
  //设置音视频资源样式
  videoElement.srcObject = stream;
  //自身的唯一ID
  videoElement.id = `${connUserSocketId}-video`;

  //onloadedmetadata在指定视频/音频（audio/video）的元数据加载后触发。
  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  //放大/缩小视频信息
  videoElement.addEventListener('click', () => {
    if (videoElement.classList.contains('full_screen')) {
      videoElement.classList.remove('full_screen');
    } else {
      videoElement.classList.add('full_screen');
    }
  });

  videoContainer.appendChild(videoElement);

  //判断哪些用户是仅开启音频//participants获取所有参与的用户
  const participants = store.getState().participants;
  //找到了匹配的用户
  const participant = participants.find((p) => p.socketId === connUserSocketId);
  //根据异步条件来判断//onlyAudio为true开启音频通话//判断用户存在并且onlyAudio为true
  if (participant?.onlyAudio) {
    //条件满足才去替换
    videoContainer.appendChild(onlyAudioLabel(participant.identity));
  } else {
    videoContainer.style.position = 'static';
  }

  videosContainer.appendChild(videoContainer);
};

//仅开启音频链接的样式效果
const onlyAudioLabel = (identity = '') => {
  //创建音频外面容器
  const labelContainer = document.createElement('div');
  //设定div样式内容
  labelContainer.classList.add('label_only_audio_container');
  //创建变量p标签显示文本
  const label = document.createElement('p');
  //文本样式设置
  label.classList.add('label_only_audio_text');
  //显示文本
  label.innerHTML = `${identity}仅开启音频连接`;
  //将文本添加到容器里面
  labelContainer.appendChild(label);
  //返回
  return labelContainer;
};
/////////////////////////////// button logic ///////////////////////////////
//麦克风音频切换//isMuted是否静音//toggleMic这个函数在麦克风按钮页面使用
export const toggleMic = (isMuted) => {
  //getAudioTracks - 返回可用的音频轨道
  //enabled - 获取或设置轨道是否激活 (true|false)//localStream对象方法//getAudioTracks轨道返回一个数组//enabled来判断音频状态
  localStream.getAudioTracks()[0].enabled = isMuted ? true : false;
};
//视频摄像头切换//isDisabled默认是开通//toggleCamera这个函数在摄像头按钮页面使用
export const toggleCamera = (isDisabled) => {
  //localStream对象方法//getAudioTracks - 返回可用的音频轨道
  //enabled - 获取或设置轨道是否激活//enabled来判断摄像头状态
  localStream.getVideoTracks()[0].enabled = isDisabled ? true : false;
};
//共享屏幕切换
export const toggleScreenShare = (
  //根据状态来决定
  isScreenSharingActive,
  //默认值为空
  screenSharingStream = null
) => {
  //判断要共享的屏幕还是本地摄像头
  if (isScreenSharingActive) {
    //展示本地的媒体流
    switchVideoTracks(localStream);
  } else {
    //展示共享屏幕媒体流
    switchVideoTracks(screenSharingStream);
  }
};
//创建函数//共享屏幕切换
const switchVideoTracks = (stream) => {
  //遍历所有对等连接对象
  for (let socket_id in peers) {
    //进行单个轨道遍历//getTracks()能够拿到所有轨道信息
    for (let index in peers[socket_id].streams[0].getTracks()) {
      //通过复循环来遍历当前接收的stream//getTracks()不同的轨道内容
      for (let index2 in stream.getTracks()) {
        //kind属性规定轨道的种类（eg:audio,video）
        if (
          //当类型匹配的时候才能替换//[index]返回一个数组
          peers[socket_id].streams[0].getTracks()[index].kind ===
          stream.getTracks()[index2].kind
        ) {
          //2个数组相等就去替换
          peers[socket_id].replaceTrack(
            //要替换的信息
            peers[socket_id].streams[0].getTracks()[index],
            //要替换的内容//接收[index2]
            stream.getTracks()[index2],
            //添加到streams[0]
            peers[socket_id].streams[0]
          );
        }
      }
    }
  }
};
