//错误提示组件
import React from 'react';
//结构errorMessage
const ErrorMessage = ({ errorMessage }) => {
  return (
    // 错误信息外部容器
    <div className='error_message_container'>
      {/* 错误存在就显示错误信息 */}
      {errorMessage && (
        // 错误信息显示
        <p className='error_message_paragraph'>{errorMessage}</p>
      )}
    </div>
  );
};

export default ErrorMessage;
