//聊天标题
import React from 'react';

const DirectChatHeader = ({ activeConversation }) => {
  return (
    //聊天标题外面容器
    <div className='direct_chat_header'>
      {/* 聊天所包括的信息 */}
      <p className='direct_chat_header_paragraph'>
        {/* 显示文本内容//activeConversation.identity显示名称 */}
        {activeConversation ? activeConversation.identity : ''}
      </p>
    </div>
  );
};

export default DirectChatHeader;
