//主体内容
import React, { useState } from 'react';
//引进输入框
import JoinRoomInputs from './JoinRoomInputs';
//引进connect 方法//用于判断isRoomHost是否主持人
import { connect } from 'react-redux';
//引进音频状态切换仓库
import OnlyWithAudioCheckbox from './OnlyWithAudioCheckbox';
import {
  //引进音频状态切换仓库
  setConnectOnlyWithAudio,
  //引进存储用户身份ID
  setRoomId,
  //引进存储用户身份姓名
  setIdentity,
} from '../../store/actions';
//引进错误提示组件
import ErrorMessage from './ErrorMessage';
//加入房间按钮切换
import JoinRoomButtons from './JoinRoomButtons';
//引进请求加入房间（sxios）
import { getRoomExists } from '../../utils/api';
//引进路由路线
import { useNavigate } from 'react-router-dom';
//用于判断isRoomHost是否主持人
const JoinRoomContent = (props) => {
  const {
    //获取判断是否主持人
    isRoomHost,
    //获取音频仓库的状态
    setConnectOnlyWithAudio,
    //获取默认音频的状态
    connectOnlyWithAudio,
    //获取存储用户身份姓名
    setIdentityAction,
    //获取存储用户身份ID
    setRoomIdAction,
  } = props;
  //roomIdValue用户ID
  const [roomIdValue, setRoomIdValue] = useState('');
  //nameValue姓名
  const [nameValue, setNameValue] = useState('');
  //错误处理
  const [errorMessage, setErrorMessage] = useState(null);
  //派发路由路线
  const navigate = useNavigate();
  //加入房间//async异步函数用于普通用户加入房间验证
  const handleJoinRoom = async () => {
    //接收存储用户身份姓名
    setIdentityAction(nameValue);
    //判断是否主持人
    if (isRoomHost) {
      //主持人创建
      createRoom();
    } else {
      //参与人员加入
      await joinRoom();
    }
  };
  ////async异步函数用于普通用户加入房间验证//joinRoom加入房间
  const joinRoom = async () => {
    //普通用户加入房间验证变量//getRoomExists验证方法放置在utils//roomIdValue传递用户ID
    const responseMessage = await getRoomExists(roomIdValue);

    const { roomExists, full } = responseMessage;

    if (roomExists) {
      if (full) {
        setErrorMessage('会议房间人数已满，请稍后再试！');
      } else {
        //接收存储用户身份ID//进入房间
        setRoomIdAction(roomIdValue);
        //路由路线重定向
        navigate('/room');
      }
    } else {
      setErrorMessage('会议房间不存在，请验证你的ID是否正确！');
    }
  };
  //createRoom创建房间
  const createRoom = () => {
    //路由路线重定向
    navigate('/room');
  };

  return (
    <>
    {/* 引进输入框 */}
      <JoinRoomInputs
      //roomIdValue用户ID
        roomIdValue={roomIdValue}
        //用于修改useState状态
        setRoomIdValue={setRoomIdValue}
        //nameValue姓名
        nameValue={nameValue}
        setNameValue={setNameValue}
        //用户状态存储在仓库
        isRoomHost={isRoomHost}
      />
      {/* 引进音频链接组件 */}
      <OnlyWithAudioCheckbox
      //获取默认音频的状态值的传递
        connectOnlyWithAudio={connectOnlyWithAudio}
        //获取音频仓库的状态值的传递
        setConnectOnlyWithAudio={setConnectOnlyWithAudio}
      />
      {/* 传递错误提示组件//errorMessage会议ID不正确//根据服务器的状态来判断 */}
      <ErrorMessage errorMessage={errorMessage} />
      {/* 加入房间按钮切换 */}
      <JoinRoomButtons
      //返回到上一级页面使用//根据isRoomHost状态显示按钮内容
        isRoomHost={isRoomHost}
        //加入房间
        handleJoinRoom={handleJoinRoom}
      />
    </>
  );
};
//用于判断isRoomHost是否主持人
const mapStateToProps = (state) => {
  
  return {
    ...state,
  };
};
//音频派发行为
const mapActionsToProps = (dispatch) => {
  //派发函数
  return {
    //音频派发
    setConnectOnlyWithAudio: (onlyWithAudio) =>
    //获取音频仓库的状态值的传递
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
      //存储用户身份姓名
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    //存储用户身份ID
    setRoomIdAction: (roomId) => dispatch(setRoomId(roomId)),
  };
};
//绑定connect用于判断isRoomHost是否主持人,传递mapActionsToProps行为
export default connect(mapStateToProps, mapActionsToProps)(JoinRoomContent);
