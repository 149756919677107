//加入房间组件
import React from 'react';

const JoinRoomTitle = ({ isRoomHost }) => {
  //文本显示变量
  const titleText = isRoomHost ? '主持会议' : '加入会议';
  //titleText显示文本
  return <p className='join_room_title'>{titleText}</p>;
};

export default JoinRoomTitle;
