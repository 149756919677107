//会议参与人员
import React from 'react';
//引进显示参与人员标题
import ParticipantsLabel from './ParticipantsLabel';
//引进所有参与人员信息
import Participants from './Participants';
//引进私信聊天组件
import DirectChat from './DirectChat/DirectChat';

const ParticipantsSection = () => {
  return (
    <>
    {/* 会议参与人员外面容器 */}
    <div className='participants_section_container'>
      {/* 标签 */}
      <ParticipantsLabel />
      {/* 参与人员内容 */}
      <Participants />
      {/* 私信聊天组件 */}
      <DirectChat />
    </div>
    </>
  );
};

export default ParticipantsSection;
