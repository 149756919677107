//麦克风按钮
import React, { useState } from 'react';
//引进麦克风图片开通状态
import MicButtonImg from '../../../resources/images/mic.svg';
//引进麦克风图片关闭状态
import MicButtonImgOff from '../../../resources/images/micOff.svg';
//引进麦克风函数
import * as webRTCHandler from '../../../utils/webRTCHandler';
const MicButton = () => {
  //麦克风默认当前状态开通
  const [isMicMuted, setIsMicMuted] = useState(false);
  //麦克风开关点击事件
  const handleMicButtonPressed = () => {
    //通过webRTCHandler调用toggleMic接收isMicMuted的状态
    webRTCHandler.toggleMic(isMicMuted);
    //开关切换
    setIsMicMuted(!isMicMuted);
  };
  return (
    <div className='video_button_container'>
      <img
      //麦克风当前状态为开通//点击为关闭
        src={isMicMuted ? MicButtonImgOff : MicButtonImg}
        //麦克风开关点击事件
        onClick={handleMicButtonPressed}
        //麦克风图片样式
        className='video_button_image' alt=''
      />
    </div>
  );
};

export default MicButton;
