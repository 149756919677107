//这是项目介绍页面按钮副级组件
import React from 'react';
//引进按钮组件
import ConnectingButton from './ConnectingButton';
//引进重定向（版本6方法）
import { useNavigate } from 'react-router-dom';

const ConnectingButtons = () => {
  //V6 useNavigate 代替了useHistory
  // push方法变成直接调用navigate(route)
  //重定向变量
  let navigate = useNavigate();

  //项目说明
  const qiyewang = () => {
    //重定向派发项目说明
    navigate('/qiyewang');
  };

  //项目说明
  const pushProjectExplained = () => {
    //重定向派发项目说明
    navigate('/notYet');
  };

  //作为用户加入会议
  const pushToJoinRoomPage = () => {
    //重定向派发//join-room是（普通用户）身份
    navigate('/join-room');
  };

  //作为主持人创建会议调用重定向
  const pushToJoinRoomPageAsHost = () => {
    //重定向派发//是（主持人）身份
    navigate('/join-room?host=true');
  };

  return (
    <>
      <div className='connecting_buttons_container'>
        {/* 项目说明 */}
        <ConnectingButton
          createRoomButton
          buttonText='企业网开店说明'
          onClickHandler={qiyewang}
        />
        {/* 项目说明 */}
        <ConnectingButton
          createRoomButton
          buttonText='视频会议说明'
          onClickHandler={pushProjectExplained}
        />
        <br />
        <ConnectingButton
          buttonText='加入会议'
          //传递加入会议参数pushToJoinRoomPage
          onClickHandler={pushToJoinRoomPage}
        />
        <ConnectingButton
          createRoomButton
          buttonText='主持会议'
          //传递主持会议参数pushToJoinRoomPageAsHost
          onClickHandler={pushToJoinRoomPageAsHost}
        />
      </div>
      <footer>
        <div>
          <div className='fixThebottom' style={{
            //居中设置
            textAlign: 'center',
            //背景颜色
            backgroundColor: '',
            //字体颜色
            color: '',
          }}><a href="https://beian.miit.gov.cn/"><strong>粤ICP备2021014359号</strong></a>&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo"><strong>备案号44162202000063</strong></a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConnectingButtons;
