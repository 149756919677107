//音频链接组件
import React from 'react';
//引进图片
import CheckImg from '../../resources/images/check.png';
const OnlyWithAudioCheckbox = ({
  //获取音频仓库的状态值的传递
  setConnectOnlyWithAudio,
  //获取默认音频的状态值的传递
  connectOnlyWithAudio,
}) => {
  //更改音频/视频状态
  const handleConnectionTypeChange = () => {
    //将连接的状态类型存储到store当中//修改!connectOnlyWithAudio状态
    setConnectOnlyWithAudio(!connectOnlyWithAudio);
  };
  return (
    <div className='checkbox_container'>
      {/* 链接类型，更改音频/视频状态  onClick点击改变√状态  handleConnectionTypeChange链接类型的更改 */}
      <div className='checkbox_connection' onClick={handleConnectionTypeChange}>
        {/* 获取默认音频的状态值的传递 */}
        {connectOnlyWithAudio && (
          //引进√图片
          <img className='checkbox_image' src={CheckImg} alt='' />
        )}
      </div>
      <p className='checkbox_container_paragraph'>只开启音频</p>
    </div>
  );
};

export default OnlyWithAudioCheckbox;
