//参与人员的标签
import React from 'react';

const ParticipantsLabel = () => {
  return (
    //参与人员的标签外面容器
    <div className='participants_label_container'>
      {/* 显示参与人员标题 */}
      <p className='participants_label_paragraph'>参与人员</p>
    </div>
  );
};

export default ParticipantsLabel;
